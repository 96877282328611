.hero {
    border-bottom-color: var(--skin-background-color-1);
}

.banner-color {
    background-color: var(--skin-banner-background-color-1);
}

h1.header.page-title,
h1.header.page-title::before {
    background-color: var(--skin-heading-color-1);
    color: var(--skin-heading-color-1-invert);
}

.product-tile {
    background-color: var(--skin-background-color-1);
    border: 10px solid var(--skin-border-color-1);
}

.refinements ul li button {
    color: var(--skin-primary-color-1);
}

.custom-select {
    background-color: var(--skin-selectbox-background-color-1);
    color: var(--skin-selectbox-text-color-1);
}

a {
    color: var(--skin-primary-color-1);
}

.price {
    color: var(--skin-price-1);
}

.btn-primary {
    background-color: #{var(--skin-primary-color-1)};
    color: var(--skin-primary-color-invert-1);
}

.btn-primary:hover {
    color: #{var(--skin-primary-color-1)};
    border-color: #{var(--skin-primary-color-1)};
    background-color: var(--skin-primary-color-invert-1);
}

.btn-primary.disabled,
.btn-primary:disabled {
    background-color: var(--skin-primary-color-1);
    border-color: var(--skin-primary-color);
    color: var(--skin-primary-color-invert-1);
    opacity: 0.5;
}

.btn-outline-primary {
    color: var(--skin-primary-color-1);
    border-color: var(--skin-primary-color-1);
}

.btn-outline-primary:hover {
    color: var(--skin-primary-color-invert-1);
    background-color: var(--skin-primary-color-1);
    border-color: var(--skin-primary-color-1);
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--skin-header-font), sans-serif;
}

a {
    color: var(--skin-link-color-1);
}
